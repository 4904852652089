"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _defaultAppData = _interopRequireDefault(require("default-app/dist/common/defaultAppData"));
var _default = (0, _objectSpread2["default"])({}, _defaultAppData["default"]);
exports["default"] = _default;