"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultDimensions = exports["default"] = void 0;
var defaultAppData = {
  "settings": {
    "basic_settings": {
      "name": {
        "lang_1": ""
      },
      "description": {
        "lang_1": ""
      },
      "languages": ["lang_1"],
      "menu": [],
      "footerMenu": []
    },
    "context": {
      "menus": {},
      "messages": {},
      "labels": {}
    }
  }
};
var defaultDimensions = {
  squareHD: {
    width: 1920,
    height: 1920,
    name: "HD square"
  },
  square4K: {
    width: 4096,
    height: 4096,
    name: "4K square"
  },
  portrait4K: {
    width: 2867,
    height: 4096,
    name: "4K portrait 70x100"
  },
  landscape4K: {
    width: 4096,
    height: 2867,
    name: "4K landscape 100x70"
  },
  portraitA: {
    width: 2897,
    height: 4096,
    name: "4K portrait A1"
  },
  landscapeA: {
    width: 4097,
    height: 2897,
    name: "4K landscape A1"
  }
};
exports.defaultDimensions = defaultDimensions;
var _default = defaultAppData;
exports["default"] = _default;